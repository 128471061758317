.css-fcq10s.css-0 {
    width: 80%;
    margin: 10px auto auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 60px;
    margin-bottom: 50px;
}
.css-1irar79 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
}
.css-42xo3i {
    padding: 25px!Important;
    font-family: poppins;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.css-2imjyh {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.css-1bnxa12 {
    display: flex;
    justify-content: space-around;
}