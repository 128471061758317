/* .background-video {
    width: 100%;
    height: 280px;
    z-index: 0; 
  } */
  
  .background-video::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
    /* position: absolute; */
    background: linear-gradient(
      45deg,
      rgba(255, 0, 102, 0.4), /* Adjust opacity here (0.5 for 50% opacity) */
      rgba(153, 255, 102, 0.4), /* Adjust opacity here (0.5 for 50% opacity) */
      rgba(0, 102, 255, 0.4), /* Adjust opacity here (0.5 for 50% opacity) */
      rgba(255, 102, 0, 0.4) /* Adjust opacity here (0.5 for 50% opacity) */
    );
    /* background-size: 400% 400%; */
    animation: gradientAnimation 10s linear infinite;
    z-index: 1; /* Place the gradient overlay above the video */
  }
  
  .background-video video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    /* position: absolute; */
    top: 0;
    left: 0;
    z-index: 0; /* Ensure the video is behind the overlay */
  }
  .about-section {
    background-color: #FAEBD7;
    padding: 20px;
    text-align: center;
    z-index: 2; /* Place the "About" section above the video overlay */
    /* position: relative; */
    /* padding-top: 70px; */
    color: black;  
    padding-bottom: 90px;
    /* max-width: 1140px; */
    width: 60%;
    margin: auto;
}
.featured-section {
  background-color: white;
  /* padding: 20px; */
  text-align: center;
  z-index: 2; /* Place the "About" section above the video overlay */
  /* position: relative; */
  padding-top: 90px;
  padding-bottom: 50px;
}
.hOHkVZ {
  max-height: fit-content!important;
}
.iIoiPO {
  max-height: fit-content!important;
}
button.slick-arrow.slick-next {
  display: none!important;
}
.slick-slide img {
  display: block;
  object-fit: cover;
}

  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

  

  
  /* Styling for react-slick slider */
  .slick-slider {
    width: 80%;
    margin: 0 auto;
  }
  
  /* Styling for custom arrows */
  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    color: #3D2B1F;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s, color 0.3s;
  }
  
  .custom-arrow-prev {
    left: -70px;
  }
  
  .custom-arrow-next {
    right: -70px;
  }
  
  .custom-arrow:hover {
    color: blue;
    background: #D4AF37;
  }
  .css-1n179ue img {
    object-fit: contain;
}
.builder_home img {
  height: 250px;
  width: 500px;
}
@media(max-width:576px)
{

  img {
    width: 100%;
    object-fit: cover;
}
  .hIlYuq,.czQBFY,.eiyWrT {
    padding: 0!important;
  }
  .css-42xo3i.css-1o1x1l9 {
    width: 100%;
    text-align: center;
}
  .kLFtpY,.NHMyM {
    width: 20%!important;
}
.css-fcq10s.css-0 {
  padding: 15px!important;
}
.css-rsz43a {
  width: 100%!important;
  background: white!important;
  padding: 10px;
}
.css-qijhcl {
  flex-direction: column!important;
}
.css-y9l3cp {
  width: 97%!important;
}
}
